import { Navigate, useRoutes } from "react-router-dom"

import { Login, Otest } from '@/page/Account'
import Home from '@/page/Home'

import { Model, DatastandardEngine, ModelDetail, Hang, Part, Smodel, Vinmodel, Categoryoe } from '@/page/Datastandard'

import Threedmodel, { Chassis, Wholevehicle, Carbody, ThreeEngine, Tpart, Tmodel, Tcar } from '@/page/Threedmodel'
import { Repairdoc } from '@/page/Threedmodel/Components/ThreedPart'

import User, { Role, Permission, ButtonsList } from '@/page/User'
import Pagecommon from '@/page/Pagecommon'
import Vin, { VinDetailPage } from '@/page/Dataedit/Vin'
import Vincon from "@/page/Dataedit/Vincon"


import { Topname, Stdclass, Jhname, Oeothername, GroupList } from '@/page/Namestd'
import { Vinumodel, Fourprice, Onlineservice } from '@/page/Dataoperation'

const nav: any = [
	{
		path: "/",
		element: <Navigate to="/home"/>
  },
  {
		path: "/:conpath",
		element: <Pagecommon/>
	},
	{
		path: "/home",
		element: <Home />,
	},
	{
		path: "/login",
		element: <Login />,
  },
	{
		path: '/otest',
		element: <Otest/>
	},

  // datastandard
  {
		path: "/datastandard/model",
		element: <Model />,
  },
  {
		path: "/datastandard/model/detail",
		element: <ModelDetail />,
  },
  {
		path: "/datastandard/engine",
		element: <DatastandardEngine />,
  },
  {
		path: "/datastandard/hang",
		element: <Hang />,
  },
  {
		path: "/datastandard/part",
		element: <Part />,
  },
	{
		path: "/datastandard/smodel",
		element: <Smodel />,
  },
	{
		path: "/datastandard/vinmodel",
		element: <Vinmodel />,
  },
	{
		path: "/datastandard/categoryoe",
		element: <Categoryoe />,
  },
	{
    path: '/datastandard/topname',
    element: <Topname/>
  },
	{
    path: '/datastandard/stdclass',
    element: <Stdclass/>
  },


	// dataeidt

	{
		path: '/dataedit',
		element: <Vincon/>,
		children: [
			{
				path: '/dataedit/vin',
				element: <Vin/>
			},
			{
				path: '/dataedit/vin/detail',
				element: <VinDetailPage/>
			},

		]
	},



  // threed
  {
		path: "/threed/:path",
		element: <Threedmodel />,
  },
  {
		path: "/threed/chassis",
		element: <Chassis />,
  },
  {
		path: "/threed/carbody",
		element: <Carbody />,
  },
  {
		path: "/threed/engine",
		element: <ThreeEngine />,
  },
  {
		path: "/threed/wholevehicle",
		element: <Wholevehicle />,
  },
  {
		path: "/threed/repairdoc",
		element: <Repairdoc />,
  },
	{
		path: '/threed/tpart',
		element: <Tpart />,
	},
	{
		path: '/threed/tmodel',
		element: <Tmodel />,
	},
	{
		path: '/threed/tcar',
		element: <Tcar />,
	},

  

  // user
  {
		path: "/usermanger/user",
		element: <User />,
  },
  {
		path: "/usermanger/role",
		element: <Role />,
  },
  {
		path: "/usermanger/permission",
		element: <Permission />,
  },
  {
		path: "/usermanger/buttonsList",
		element: <ButtonsList />,
  },

  // 名称标准化
  {
    path: '/namestd/topname',
    element: <Topname/>
  },
	{
    path: '/namestd/stdclass',
    element: <Stdclass/>
  },
	{
		path: '/namestd/jhname',
		element: <Jhname/>
	},
	{
		path: '/namestd/oeother',
		element: <Oeothername/>
	},
	{
		path: '/namestd/grouplist',
		element: <GroupList/>
	},




	// 数据运营
	{
		path: '/dataoperation/vinumodel',
		element: <Vinumodel/>
	},
	{
		path: '/dataoperation/fourprice',
		element: <Fourprice/>
	},
	{
		path: '/dataoperation/onlineservice',
		element: <Onlineservice/>
	},


]

const Router = () => {
	const routes = useRoutes(nav)
  return routes
}

export default Router