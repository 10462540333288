import React from "react";

const data: any = {
  "document_id": "AP4210P4210Z",
  "document_name": "制动系统 - 检查工作液液位",
  "data": {
    "title": {
      "ordkt": "文件",
      "figcontent": "图片",
      "ast": "工作步骤",
      "basdat": "基本数据",
      "anzieh": "拧紧力矩",
      "fuell": "加注量"
    },
    "content": {
      "ordkt": [
        {
          "title": [
            [
              "车型",
              "124, 129, 140, 163, 168, 170, 202, 203, 208, 209, 210, 211, 215, 230"
            ],
            [
              "车型",
              "414"
            ],
            [
              "车型",
              "220, 防弹车型/代码 (Z07) 除外"
            ]
          ],
          "table": [
            {
              "sort": [
                "col_3399",
                "col_14179",
                "col_15599"
              ],
              "col_3399": {
                "row_1": [
                  "1,1,0,1",
                  "AP42.10-P-4210Z"
                ]
              },
              "col_14179": {
                "row_1": [
                  "1,1,0,1",
                  "制动系统 - 检查工作液液位"
                ]
              },
              "col_15599": {
                "row_1": [
                  "1,1,1,1",
                  "22.3.12"
                ]
              }
            }
          ]
        }
      ],
      "figcontent": [
        {
          "title": [
            [
              "P42.00-2049-01"
            ],
            [
              ".P.p4200204901|8.506173|6.389771|TIFF;;"
            ],
            [
              "图示为车型 230"
            ],
            [
              "10",
              "制动液储存容器"
            ]
          ],
          "table": [
            
          ]
        }
      ],
      "ast": [
        {
          "title": [
            
          ],
          "table": [
            {
              "sort": [
                "col_2839",
                "col_7799",
                "col_12759",
                "col_15598"
              ],
              "col_2839": {
                "row_1": [
                  "1,1,0,0",
                  "g",
                  " "
                ],
                "row_2": [
                  "1,1,0,0",
                  "a",
                  " ",
                  "危险!"
                ],
                "row_3": [
                  "1,1,0,0",
                  "a",
                  " ",
                  "危险!"
                ],
                "row_4": [
                  "1,1,0,0",
                  "p",
                  " "
                ],
                "row_5": [
                  "1,1,0,0",
                  "p",
                  " "
                ],
                "row_6": [
                  "1,1,0,0",
                  "1"
                ],
                "row_7": [
                  "1,1,0,0",
                  "2"
                ],
                "row_8": [
                  "0,1,0,0"
                ],
                "row_9": [
                  "0,1,0,0"
                ],
                "row_10": [
                  "0,1,0,0"
                ],
                "row_11": [
                  "1,1,0,1",
                  "3"
                ]
              },
              "col_7799": {
                "row_1": [
                  "1,1,0,0",
                  "检查"
                ],
                "row_2": [
                  "1,1,0,0",
                  "在拆卸, 安装或校准发动机罩, 车门, 行李箱盖, 掀开式背门或滑动天窗时有夹伤或压伤手指的危险."
                ],
                "row_3": [
                  "1,1,0,0",
                  "吞入制动液有导致中毒的风险. 皮肤和眼睛接触制动液有导致受伤的风险."
                ],
                "row_4": [
                  "1,1,0,0",
                  "关于制动液的说明"
                ],
                "row_5": [
                  "1,1,0,0",
                  "关于修理制动系统的说明"
                ],
                "row_6": [
                  "1,1,0,0",
                  "拆下制动液储存容器 (10) 上方的盖"
                ],
                "row_7": [
                  "1,1,0,0",
                  "检查制动液储液罐 (10) 中的工作液液位"
                ],
                "row_8": [
                  "0,1,0,0"
                ],
                "row_9": [
                  "0,1,0,0"
                ],
                "row_10": [
                  "0,1,0,0"
                ],
                "row_11": [
                  "1,1,0,1",
                  "将护盖安装到制动液储液罐 (10) 上"
                ]
              },
              "col_12759": {
                "row_1": [
                  "1,1,0,0"
                ],
                "row_2": [
                  "1,1,0,0",
                  "确保肢体远离运动的部件."
                ],
                "row_3": [
                  "1,1,0,0",
                  "只能将制动液倒入合适且正确标记的容器中. 处理制动液时, 穿戴防护服和护目镜."
                ],
                "row_4": [
                  "1,1,0,0"
                ],
                "row_5": [
                  "1,1,0,0"
                ],
                "row_6": [
                  "1,1,0,0",
                  "车型 209, 黑色系列/代码 (P98)"
                ],
                "row_7": [
                  "1,1,0,0",
                  "i",
                  " 工作液液位必须处于制动液储存容器 (10) 上的 最低 (MIN) 和 最高 (MAX) 标记之间."
                ],
                "row_8": [
                  "0,1,0,0",
                  "如果液位过高, 则抽出制动液, 直至液位达到 最高 (MAX) 标记. ",
                  "如果工作液液位过低, 则确定原因并排除故障, 需另开施工单."
                ],
                "row_9": [
                  "0,1,0,0",
                  "n",
                  " ",
                  "车型 211, 230"
                ],
                "row_10": [
                  "0,1,0,0",
                  "j"
                ],
                "row_11": [
                  "1,1,0,1",
                  "车型 209, 黑色系列/代码 (P98)"
                ]
              },
              "col_15598": {
                "row_1": [
                  "1,1,1,0"
                ],
                "row_2": [
                  "1,1,1,0",
                  ".T.1;AS00.00-Z-0011-01A;"
                ],
                "row_3": [
                  "1,1,1,0",
                  ".T.1;AS42.50-Z-0001-01A;"
                ],
                "row_4": [
                  "1,1,1,0",
                  ".T.1;AH42.50-P-0001-01A;"
                ],
                "row_5": [
                  "1,1,1,0",
                  ".T.1;AH42.00-P-0003-01A;"
                ],
                "row_6": [
                  "1,1,1,0"
                ],
                "row_7": [
                  "1,1,1,0"
                ],
                "row_8": [
                  "0,1,1,0"
                ],
                "row_9": [
                  "0,1,1,0",
                  ".T.1;*BA42.50-P-1001-01D;"
                ],
                "row_10": [
                  "0,1,1,0",
                  ".T.1;*BF42.10-P-1001-01A;"
                ],
                "row_11": [
                  "1,1,1,1"
                ]
              }
            }
          ]
        }
      ],
      "basdat": [
        {
          "title": [
            [
              "n",
              "液压式制动系统"
            ]
          ],
          "table": [
            
          ]
        }
      ],
      "anzieh": [
        {
          "title": [
            [
              "j",
              "制动液"
            ]
          ],
          "table": [
            {
              "sort": [
                "col_2839",
                "col_9199",
                "col_11318",
                "col_7799",
                "col_8499"
              ],
              "col_2839": {
                "row_1": [
                  "1,1,0,0",
                  "编号"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,1",
                  "BA42.50-P-1001-01D"
                ]
              },
              "col_9199": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,1",
                  "Nm"
                ]
              },
              "col_11318": {
                "row_1": [
                  "0,1,1,0"
                ],
                "row_2": [
                  "0,1,1,0"
                ],
                "row_3": [
                  "1,1,1,1",
                  "≈2"
                ]
              },
              "col_7799": {
                "row_1": [
                  "0,1,0,0"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,1",
                  "连接盖到制动液膨胀容器"
                ]
              },
              "col_8499": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,1"
                ]
              }
            }
          ]
        }
      ],
      "fuell": [
        {
          "title": [
            [
              "j",
              "制动液"
            ]
          ],
          "table": [
            {
              "sort": [
                "col_2839",
                "col_9917",
                "col_14155",
                "col_5678",
                "col_9217",
                "col_12036"
              ],
              "col_2839": {
                "row_1": [
                  "1,1,0,0",
                  "编号"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "BF42.10-P-1001-01A"
                ],
                "row_4": [
                  "0,1,0,0"
                ],
                "row_5": [
                  "0,1,0,1"
                ]
              },
              "col_9917": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,0",
                  "升"
                ],
                "row_4": [
                  "1,0,0,0",
                  "图表"
                ],
                "row_5": [
                  "1,0,0,1",
                  "图表"
                ]
              },
              "col_12036": {
                "row_1": [
                  "0,1,0,0"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "≈0.45 至 0.6"
                ],
                "row_4": [
                  "1,1,0,0",
                  ".T.1;BB00.40-P-0330-01A;"
                ],
                "row_5": [
                  "1,1,0,1",
                  ".T.1;BB00.40-P-0331-00A;"
                ]
              },
              "col_14155": {
                "row_1": [
                  "0,1,1,0"
                ],
                "row_2": [
                  "0,1,1,0"
                ],
                "row_3": [
                  "1,1,1,0",
                  "≈0.45 至 0.6"
                ],
                "row_4": [
                  "1,1,1,0",
                  ".T.1;BB00.40-P-0330-01A;"
                ],
                "row_5": [
                  "1,1,1,1",
                  ".T.1;BB00.40-P-0331-00A;"
                ]
              },
              "col_5678": {
                "row_1": [
                  "0,1,0,0"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "制动液"
                ],
                "row_4": [
                  "0,1,0,0"
                ],
                "row_5": [
                  "0,1,0,1"
                ]
              },
              "col_9217": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,0",
                  "加注容积"
                ],
                "row_4": [
                  "1,0,0,0",
                  "工作液规格"
                ],
                "row_5": [
                  "0,0,0,1"
                ]
              }
            }
          ]
        },
        {
          "title": [
            [
              "j",
              "制动液"
            ]
          ],
          "table": [
            {
              "sort": [
                "col_2839",
                "col_9917",
                "col_14155",
                "col_5678",
                "col_9217",
                "col_12036"
              ],
              "col_2839": {
                "row_1": [
                  "1,1,0,0",
                  "编号"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "BF42.10-P-1001-01A"
                ],
                "row_4": [
                  "0,1,0,0"
                ],
                "row_5": [
                  "0,1,0,1"
                ]
              },
              "col_9917": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,0",
                  "升"
                ],
                "row_4": [
                  "1,0,0,0",
                  "图表"
                ],
                "row_5": [
                  "1,0,0,1",
                  "图表"
                ]
              },
              "col_12036": {
                "row_1": [
                  "0,1,0,0"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "≈0.7 至 0.9"
                ],
                "row_4": [
                  "1,1,0,0",
                  ".T.1;BB00.40-P-0330-01A;"
                ],
                "row_5": [
                  "1,1,0,1",
                  ".T.1;BB00.40-P-0331-00A;"
                ]
              },
              "col_14155": {
                "row_1": [
                  "0,1,1,0"
                ],
                "row_2": [
                  "0,1,1,0"
                ],
                "row_3": [
                  "1,1,1,0",
                  "≈0.7 至 0.9"
                ],
                "row_4": [
                  "1,1,1,0",
                  ".T.1;BB00.40-P-0330-01A;"
                ],
                "row_5": [
                  "1,1,1,1",
                  ".T.1;BB00.40-P-0331-00A;"
                ]
              },
              "col_5678": {
                "row_1": [
                  "0,1,0,0"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "制动液"
                ],
                "row_4": [
                  "0,1,0,0"
                ],
                "row_5": [
                  "0,1,0,1"
                ]
              },
              "col_9217": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,0",
                  "加注容积"
                ],
                "row_4": [
                  "1,0,0,0",
                  "工作液规格"
                ],
                "row_5": [
                  "0,0,0,1"
                ]
              }
            }
          ]
        },
        {
          "title": [
            [
              "j",
              "制动液"
            ]
          ],
          "table": [
            {
              "sort": [
                "col_2839",
                "col_9917",
                "col_14155",
                "col_5678",
                "col_9217",
                "col_12036"
              ],
              "col_2839": {
                "row_1": [
                  "1,1,0,0",
                  "编号"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "BF42.10-P-1001-01A"
                ],
                "row_4": [
                  "0,1,0,0"
                ],
                "row_5": [
                  "0,1,0,1"
                ]
              },
              "col_9917": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,0",
                  "升"
                ],
                "row_4": [
                  "1,0,0,0",
                  "图表"
                ],
                "row_5": [
                  "1,0,0,1",
                  "图表"
                ]
              },
              "col_12036": {
                "row_1": [
                  "0,1,0,0"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "≈0.6"
                ],
                "row_4": [
                  "1,1,0,0",
                  ".T.1;BB00.40-P-0330-01A;"
                ],
                "row_5": [
                  "1,1,0,1",
                  ".T.1;BB00.40-P-0331-00A;"
                ]
              },
              "col_14155": {
                "row_1": [
                  "0,1,1,0"
                ],
                "row_2": [
                  "0,1,1,0"
                ],
                "row_3": [
                  "1,1,1,0",
                  "≈0.45 至 0.6"
                ],
                "row_4": [
                  "1,1,1,0",
                  ".T.1;BB00.40-P-0330-01A;"
                ],
                "row_5": [
                  "1,1,1,1",
                  ".T.1;BB00.40-P-0331-00A;"
                ]
              },
              "col_5678": {
                "row_1": [
                  "0,1,0,0"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "制动液"
                ],
                "row_4": [
                  "0,1,0,0"
                ],
                "row_5": [
                  "0,1,0,1"
                ]
              },
              "col_9217": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,0",
                  "加注容积"
                ],
                "row_4": [
                  "1,0,0,0",
                  "工作液规格"
                ],
                "row_5": [
                  "0,0,0,1"
                ]
              }
            }
          ]
        },
        {
          "title": [
            [
              "j",
              "制动液"
            ]
          ],
          "table": [
            {
              "sort": [
                "col_2839",
                "col_9917",
                "col_14155",
                "col_5678",
                "col_9217",
                "col_12036"
              ],
              "col_2839": {
                "row_1": [
                  "1,1,0,0",
                  "编号"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "BF42.10-P-1001-01A"
                ],
                "row_4": [
                  "0,1,0,0"
                ],
                "row_5": [
                  "0,1,0,1"
                ]
              },
              "col_9917": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,0",
                  "升"
                ],
                "row_4": [
                  "1,0,0,0",
                  "图表"
                ],
                "row_5": [
                  "1,0,0,1",
                  "图表"
                ]
              },
              "col_12036": {
                "row_1": [
                  "0,1,0,0"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "≈0.45 至 0.6"
                ],
                "row_4": [
                  "1,1,0,0",
                  ".T.1;BB00.40-P-0330-01A;"
                ],
                "row_5": [
                  "1,1,0,1",
                  ".T.1;BB00.40-P-0331-00A;"
                ]
              },
              "col_14155": {
                "row_1": [
                  "0,1,1,0"
                ],
                "row_2": [
                  "0,1,1,0"
                ],
                "row_3": [
                  "1,1,1,0",
                  "≈0.45 至 0.6"
                ],
                "row_4": [
                  "1,1,1,0",
                  ".T.1;BB00.40-P-0330-01A;"
                ],
                "row_5": [
                  "1,1,1,1",
                  ".T.1;BB00.40-P-0331-00A;"
                ]
              },
              "col_5678": {
                "row_1": [
                  "0,1,0,0"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "制动液"
                ],
                "row_4": [
                  "0,1,0,0"
                ],
                "row_5": [
                  "0,1,0,1"
                ]
              },
              "col_9217": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,0",
                  "加注容积"
                ],
                "row_4": [
                  "1,0,0,0",
                  "工作液规格"
                ],
                "row_5": [
                  "0,0,0,1"
                ]
              }
            }
          ]
        },
        {
          "title": [
            [
              "j",
              "制动液"
            ]
          ],
          "table": [
            {
              "sort": [
                "col_2839",
                "col_9917",
                "col_14155",
                "col_5678",
                "col_9217",
                "col_12036"
              ],
              "col_2839": {
                "row_1": [
                  "1,1,0,0",
                  "编号"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "BF42.10-P-1001-01A"
                ],
                "row_4": [
                  "0,1,0,0"
                ],
                "row_5": [
                  "0,1,0,1"
                ]
              },
              "col_9917": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,0"
                ],
                "row_4": [
                  "1,0,0,0"
                ],
                "row_5": [
                  "1,0,0,1"
                ]
              },
              "col_12036": {
                "row_1": [
                  "0,1,0,0"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "≈0.45 至 0.6"
                ],
                "row_4": [
                  "1,1,0,0",
                  ".T.1;BB00.40-P-0330-01A;"
                ],
                "row_5": [
                  "1,1,0,1",
                  ".T.1;BB00.40-P-0331-00A;"
                ]
              },
              "col_14155": {
                "row_1": [
                  "0,1,1,0"
                ],
                "row_2": [
                  "0,1,1,0"
                ],
                "row_3": [
                  "1,1,1,0",
                  "≈0.45 至 0.6"
                ],
                "row_4": [
                  "1,1,1,0",
                  ".T.1;BB00.40-P-0330-01A;"
                ],
                "row_5": [
                  "1,1,1,1",
                  ".T.1;BB00.40-P-0331-00A;"
                ]
              },
              "col_5678": {
                "row_1": [
                  "0,1,0,0"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "制动液"
                ],
                "row_4": [
                  "0,1,0,0"
                ],
                "row_5": [
                  "0,1,0,1"
                ]
              },
              "col_9217": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,0",
                  "加注容积"
                ],
                "row_4": [
                  "1,0,0,0",
                  "工作液规格"
                ],
                "row_5": [
                  "0,0,0,1"
                ]
              }
            }
          ]
        },
        {
          "title": [
            [
              "j",
              "制动液"
            ]
          ],
          "table": [
            {
              "sort": [
                "col_2839",
                "col_9917",
                "col_14155",
                "col_5678",
                "col_9217",
                "col_12036"
              ],
              "col_2839": {
                "row_1": [
                  "1,1,0,0",
                  "编号"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "BF42.10-P-1001-01A"
                ],
                "row_4": [
                  "0,1,0,0"
                ],
                "row_5": [
                  "0,1,0,1"
                ]
              },
              "col_9917": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,0"
                ],
                "row_4": [
                  "1,0,0,0"
                ],
                "row_5": [
                  "1,0,0,1"
                ]
              },
              "col_12036": {
                "row_1": [
                  "0,1,0,0"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "≈0.45 至 0.6"
                ],
                "row_4": [
                  "1,1,0,0",
                  ".T.1;BB00.40-P-0330-01A;"
                ],
                "row_5": [
                  "1,1,0,1",
                  ".T.1;BB00.40-P-0331-00A;"
                ]
              },
              "col_14155": {
                "row_1": [
                  "0,1,1,0"
                ],
                "row_2": [
                  "0,1,1,0"
                ],
                "row_3": [
                  "1,1,1,0",
                  "≈1.4 至 2.25"
                ],
                "row_4": [
                  "1,1,1,0",
                  ".T.1;BB00.40-P-0330-01A;"
                ],
                "row_5": [
                  "1,1,1,1",
                  ".T.1;BB00.40-P-0331-00A;"
                ]
              },
              "col_5678": {
                "row_1": [
                  "0,1,0,0"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "制动液"
                ],
                "row_4": [
                  "0,1,0,0"
                ],
                "row_5": [
                  "0,1,0,1"
                ]
              },
              "col_9217": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,0",
                  "加注容积"
                ],
                "row_4": [
                  "1,0,0,0",
                  "工作液规格"
                ],
                "row_5": [
                  "0,0,0,1"
                ]
              }
            }
          ]
        },
        {
          "title": [
            [
              "j",
              "制动液"
            ]
          ],
          "table": [
            {
              "sort": [
                "col_2839",
                "col_9917",
                "col_14155",
                "col_5678",
                "col_9217",
                "col_12036"
              ],
              "col_2839": {
                "row_1": [
                  "1,1,0,0",
                  "编号"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "BF42.10-P-1001-01A"
                ],
                "row_4": [
                  "0,1,0,0"
                ],
                "row_5": [
                  "0,1,0,1"
                ]
              },
              "col_9917": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,0",
                  "升"
                ],
                "row_4": [
                  "1,0,0,0",
                  "图表"
                ],
                "row_5": [
                  "1,0,0,1",
                  "图表"
                ]
              },
              "col_12036": {
                "row_1": [
                  "0,1,0,0"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "≈0.7 至 0.9"
                ],
                "row_4": [
                  "1,1,0,0",
                  ".T.1;BB00.40-P-0330-01A;"
                ],
                "row_5": [
                  "1,1,0,1",
                  ".T.1;BB00.40-P-0331-00A;"
                ]
              },
              "col_14155": {
                "row_1": [
                  "0,1,1,0"
                ],
                "row_2": [
                  "0,1,1,0"
                ],
                "row_3": [
                  "1,1,1,0",
                  "≈0.7 至 0.9"
                ],
                "row_4": [
                  "1,1,1,0",
                  ".T.1;BB00.40-P-0330-01A;"
                ],
                "row_5": [
                  "1,1,1,1",
                  ".T.1;BB00.40-P-0331-00A;"
                ]
              },
              "col_5678": {
                "row_1": [
                  "0,1,0,0"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "制动液"
                ],
                "row_4": [
                  "0,1,0,0"
                ],
                "row_5": [
                  "0,1,0,1"
                ]
              },
              "col_9217": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,0",
                  "加注容积"
                ],
                "row_4": [
                  "1,0,0,0",
                  "工作液规格"
                ],
                "row_5": [
                  "0,0,0,1"
                ]
              }
            }
          ]
        },
        {
          "title": [
            [
              "j",
              "制动液"
            ]
          ],
          "table": [
            {
              "sort": [
                "col_2839",
                "col_9917",
                "col_14155",
                "col_5678",
                "col_9217",
                "col_12036"
              ],
              "col_2839": {
                "row_1": [
                  "1,1,0,0",
                  "编号"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "BF42.10-P-1001-01A"
                ],
                "row_4": [
                  "0,1,0,0"
                ],
                "row_5": [
                  "0,1,0,1"
                ]
              },
              "col_9917": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,0",
                  "升"
                ],
                "row_4": [
                  "1,0,0,0",
                  "图表"
                ],
                "row_5": [
                  "1,0,0,1",
                  "图表"
                ]
              },
              "col_12036": {
                "row_1": [
                  "0,1,0,0"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "≈1.4 至 2.25"
                ],
                "row_4": [
                  "1,1,0,0",
                  ".T.1;BB00.40-P-0330-01A;"
                ],
                "row_5": [
                  "1,1,0,1",
                  ".T.1;BB00.40-P-0331-00A;"
                ]
              },
              "col_14155": {
                "row_1": [
                  "0,1,1,0"
                ],
                "row_2": [
                  "0,1,1,0"
                ],
                "row_3": [
                  "1,1,1,0",
                  "≈0.76"
                ],
                "row_4": [
                  "1,1,1,0",
                  ".T.1;BB00.40-P-0330-01A;"
                ],
                "row_5": [
                  "1,1,1,1",
                  ".T.1;BB00.40-P-0331-00A;"
                ]
              },
              "col_5678": {
                "row_1": [
                  "0,1,0,0"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "制动液"
                ],
                "row_4": [
                  "0,1,0,0"
                ],
                "row_5": [
                  "0,1,0,1"
                ]
              },
              "col_9217": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,0",
                  "加注容积"
                ],
                "row_4": [
                  "1,0,0,0",
                  "工作液规格"
                ],
                "row_5": [
                  "0,0,0,1"
                ]
              }
            }
          ]
        },
        {
          "title": [
            
          ],
          "table": [
            {
              "sort": [
                "col_2839",
                "col_9917",
                "col_12036",
                "col_5678",
                "col_9217"
              ],
              "col_2839": {
                "row_1": [
                  "1,1,0,0",
                  "编号"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "BF42.10-P-1001-01A"
                ],
                "row_4": [
                  "0,1,0,0"
                ],
                "row_5": [
                  "0,1,0,1"
                ]
              },
              "col_9917": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,0",
                  "升"
                ],
                "row_4": [
                  "1,0,0,0",
                  "图表"
                ],
                "row_5": [
                  "1,0,0,1",
                  "图表"
                ]
              },
              "col_12036": {
                "row_1": [
                  "0,1,1,0"
                ],
                "row_2": [
                  "0,1,1,0"
                ],
                "row_3": [
                  "1,1,1,0",
                  "≈0.83"
                ],
                "row_4": [
                  "1,1,1,0",
                  ".T.1;BB00.40-P-0330-01A;"
                ],
                "row_5": [
                  "1,1,1,1",
                  ".T.1;BB00.40-P-0331-00A;"
                ]
              },
              "col_5678": {
                "row_1": [
                  "0,1,0,0"
                ],
                "row_2": [
                  "0,1,0,0"
                ],
                "row_3": [
                  "1,1,0,0",
                  "制动液"
                ],
                "row_4": [
                  "0,1,0,0"
                ],
                "row_5": [
                  "0,1,0,1"
                ]
              },
              "col_9217": {
                "row_1": [
                  "0,0,0,0"
                ],
                "row_2": [
                  "0,0,0,0"
                ],
                "row_3": [
                  "1,0,0,0",
                  "加注容积"
                ],
                "row_4": [
                  "1,0,0,0",
                  "工作液规格"
                ],
                "row_5": [
                  "0,0,0,1"
                ]
              }
            }
          ]
        }
      ]
    },
    "sort": [
      "ordkt",
      "figcontent",
      "ast",
      "basdat",
      "anzieh",
      "fuell"
    ]
  }
}

const contents: any = data.data.content
const contentsSort: any = data.data.sort
const titles: any = data.data.title

const Otest: React.FC<any> = (props) => {
  const { docData } = props
  const contents: any = docData.data.content
  const contentsSort: any = docData.data.sort
  const titles: any = docData.data.title
  console.log(contents)
  return (
    <div className="bgc-fff">
      {
        Object.keys(contents).map((key: string) => {
          let itemTitle: any = contents[key].title
          let itemTable: any = contents[key].table
          let items = contents[key]
          return (
            <>
              <h2 className="center" >{titles[key]}</h2>
              {
                items.map((item: any, index: number) => {
                  let itemTitle: any = item.title
                  let itemTable: any = item.table
                  console.log(itemTitle, itemTable)
                  return (
                    <div>
                      <div  >
                        {
                          itemTitle.map((titles: any) => <div  >
                            {
                              titles.map((title: any) => title && title.includes("<img>") ? <img src={title.split("<img>")[1]} /> : title)
                            }
                          </div>)
                        }
                      </div>
                      <div style={{ width: '100%' }}>
                        {
                          itemTable.length ? itemTable.filter((k: string) => k !== 'sort').map((cols: any) => {
                            // console.log(cols)
                            let col: any = []
                            let sort = cols.sort || []
                            let len = sort.length
                            for(let i = 0; i < len; i++) {
                              let rowKey = sort[i]
                              col.push(cols[rowKey])
                            }
                            // console.log(col)
                            return (
                              <div className="center flex-s-c" style={{  width: (100 / itemTable.length) + '%' }}>
                                {
                                  col.map((rows: any) => {
                                    return (
                                      <div style={{ width: (100 / col.length) + '%' }}>
                                        {
                                          Object.values(rows).map((row: any) => {
                                            let text = row.filter((it: any, i: number) => i).join(" ")
                                            let borderLeft, borderRight, borderTop, borderBottom
                                            let borderData =  row[0].split(",")
                                            borderTop = borderData[0] + 'px solid #000'
                                            borderLeft = borderData[1] + 'px solid #000'
                                            borderRight = borderData[2] + 'px solid #000'
                                            borderBottom = borderData[3] + 'px solid #000'
                                            console.log(borderTop, borderLeft, borderRight, borderBottom)
                                            return (
                                              <div 
                                                className="center flex-c-c" 
                                                style={{ 
                                                  // border: '1px solid #000', 
                                                  height: 54, 
                                                  overflow: 'hidden',  
                                                  // border: `1px solid #000`,
                                                  borderTop,
                                                  borderLeft,
                                                  borderRight,
                                                  borderBottom
                                                }}
                                              >
                                                {text || ""}
                                              </div>
                                            )
                                          })
                                        }
                                      </div>
                                      
                                    )
                                  })
                                }
                              </div>
                            )
                          }): null
                        }
                      </div>

                    </div>
                  )
                })
              }
            </>
            
          )
        })
      }
    </div>
  )
}

export default Otest