import { AutoComplete, Button, Checkbox, Input, message, Modal, Select, Spin, Tag } from 'antd'
import React, { useEffect, useRef, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import context from '@/context/MainContext'
import dataApi from '@/api/dataApi'
import axios from 'axios'
import namestdApi from '@/api/namestdApi'
import dataOperationApi from '@/api/dataOperationApi'

import { Otest } from '../Account'

const CancelToken = axios.CancelToken
const controller = new AbortController();

let indexObj: any = {}


let timeout: any = null
let activeIndex: any = -1



const Onlineservice: React.FC<any> = (props) => {
  const { store } = useContext(context)
  const { writeAuth } = store
  const { pathname } = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  const [docOpen, setDocOpen] = useState<boolean>(false)
  const [docData, setDocData] = useState<any>({})
  
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  const [activeItem, setActiveItem] = useState<any>({})

  const [keyword, setkeyword] = useState<string>("")
  const [nameOptions, setNameOptions] = useState<any>([])
  const [standardOption, setStandardOption] = useState<any>([])

  let jexcelDivRef: any = useRef<HTMLDivElement>(null)
  let jexcelRef: any = useRef<any>({})

  const keywordRef = useRef<any>(0)
  const fidRef = useRef<any>(0)

  let getCancel = useRef<any>(null)

  let header = [
    {
      label: '文档唯一ID',
      key: 'uuid'
    },
    {
      label: '目录序号',
      key: 'num'
    },
    {
      label: '品牌',
      key: 'brand'
    },
    {
      label: '文档标题',
      key: 'label'
    },
    {
      label: '关联标准名称',
      key: 'stdname'
    },
    {
      label: '操作',
      key: 'operate'
    },
  ]

  
  

  useEffect(() => {
    getList()
    getSelectStandardlist()
    initExcel()
    return () => {
      jexcelDivRef.current = null
      jexcelRef.current = {}
      keywordRef.current = null
      fidRef.current = null
    }
  },[])

  useEffect(() => {
    if(jexcelDivRef.current && JSON.stringify(jexcelRef.current ) !== "{}") {
      keywordRef.current = keyword || ""
    }
  },[keyword])

  const searchNameList = (value: any) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    getCancel.current && getCancel.current('请求中断…')
    const rToken = new CancelToken(c => getCancel.current = c)
    const getCompanyOption = () => {
      namestdApi.getSelectNamelist({
        cancelToken: rToken,
        signal: controller.signal,
        name: value
      }).then((res: any) => {
        if(res.code === 1) {
          let _list = res.data.map((item: any) => {
            return {
              label: item,
              value: item
            }
          })
          setNameOptions(_list)
        }
      })
    }
    if (value) {
      timeout = setTimeout(getCompanyOption, 300);
    } else {
      setNameOptions([]);
    }
  }

  const getSelectStandardlist = () => {
    namestdApi.getSelectStandardlist({name: '123'}).then((res: any) => {
      if(res.code === 1) {
        let _list = res.data.map((item: any) => {
          return {
            label: item.standlabel,
            value: item.standlabel,
            ...item
          }
          
        })
        setStandardOption(_list)
      }
    })
    return false
  }

  const initExcel = async () => {

    let excel: any = document.getElementById(pathname)
    let width = excel?.clientWidth
    let height = excel?.clientHeight
    let len = header.length
    let options: any = {
      showGrid: true,
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      mode: 'edit', //edit
      row: {
        len: 50000,
      },
      col: {
        len,
        width: 1200 / len
      },
      view: {
        height: ()=>{return height},
        width: ()=>{return width},
      },
      
    }
    
    jexcelRef.current = new (window as any).x_spreadsheet(jexcelDivRef.current, options)
    
    jexcelRef.current.on('cell-selected', (e: any, ri: any, ci: any) => {
      if(ri > 0) {
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        console.log(rowData)
        let uuid = rowData[indexObj['uuid']] ? rowData[indexObj['uuid']].text : ''
        if(!uuid) return false
        let stdname = rowData[indexObj['stdname']] ? rowData[indexObj['stdname']].text : ''
        if(ci === indexObj['stdname'] || ci === indexObj['operate']) {
          setActiveItem({
            uuid,
            stdname
          })
          activeIndex = ri
          if(ci === indexObj['stdname']) {
            setOpen(true)
            console.log(456)
          }else {
            let params = {
              uuid: uuid
            }
            console.log(123)
            dataOperationApi.showdoc(params).then((res: any) => {
              if(res.code === 1) {
                setDocOpen(true)
                setDocData(res.data)
              }
            })
          }
        }
      }
      
    })

    
    
    
    jexcelRef.current.validate()
  }
  const initData = async (list: any, headers = header) => {
    let _rows: any = { len: list.length + 1 }
    let cells: any = []
    headers.forEach((item: any) => {
      cells.push({
        text: item.label,
        editable: false,
        style: 0
      })
    })
    _rows[0] = {
      cells
    }
    indexObj = {}
    
    list.forEach((items: any, j: number) => {
      let cells: any = []
      headers.forEach((item: any, index: number) => {
        indexObj[item.key] = index
        let text = items[item.key] || ''
        let editable = true
        let styleObj = {}
        if(item.key === 'operate') {
          editable = false
          styleObj = {style: 2}
          text = '查看'
        }else if(item.key === 'name') {
          editable = false
        }
        cells.push({
          text,
          editable,
          ...styleObj
        })
      })
      _rows[j + 1] = {
        cells
      }
    })

    let excelDataObj = {
      name: '',
      merges: [],
      rows: _rows,
      autofilter: {
        filters: [],
        ref: 'A1:O1'
      },
      cols: {
        
      },
      freeze: "A2",
      styles: [
        {
          bgcolor: '#d8d8d8',
          font: {
            bold: true
          }
        },
        {
          color: '#ff0000'
        },
        {
          color: '#ec8d00'
        }
      ]
    }
    jexcelRef.current.loadData([excelDataObj])
  }

  const getList = () => {
    setLoading(true)
    dataOperationApi.getOnlineService({search: keywordRef.current || ""}).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        initData(res.data)
      }
    })
  }


  const submit = (inputItem?: any) => {
    let item = inputItem || activeItem
    let api: any
    if(item.uuid) {
      api = dataOperationApi.updateOnlineServiceStd(item)
    }
    setSubmitLoading(true)
    api.then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        // if(item.fid) {
        //   jexcelRef.current.datas[0].rows.setCell(activeIndex, indexObj['standlabel'], {
        //     text: item.standlabel,
        //   }, 'all')
        //   jexcelRef.current.datas[0].rows.setCell(activeIndex, indexObj['pos'], {
        //     text: item.pos,
        //   }, 'all')
        //   jexcelRef.current.reRender()
        // }
        getList()
        setOpen(false)
        message.success("操作成功！")
      }
    })
  }
  return (
    <div className='p10 bgc-fff' >
      {/* <Datatopbox/> */}
      <Spin spinning={loading}>
        <div className='flex-b-c'>
          <div className='flex-s-c'>
            <Input.Search
              placeholder='车架号/车型id'
              onChange={(e: any) => setkeyword(e.target.value)}
              onSearch={(value: any) => getList()}
            />
          </div>
        </div>
        <div className='mt10' style={{ height: 'calc(100vh - 100px)' }} ref={jexcelDivRef} id={pathname}></div>
      </Spin>
      

      <Modal
        title={`关联标准名称`}
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => submit()}
        okButtonProps={{
          loading: submitLoading
        }}
      >
        <div className='mtb20'>
          <div className='flex-s-c mt10'>
            <label className='right' style={{ width: 100 }}><span className='red'>*</span>标准名称：</label>
            <Select
              mode="tags" 
              showSearch
              style={{ flex: 1 }} 
              value={activeItem.stdname} 
              options={
                standardOption
              }
              filterOption={(input, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value: any, option: any) => setActiveItem({...activeItem, stdname: value ? value.join(",") : ''})} 
            />
          </div>
        </div>
      </Modal>


      <Modal
        title={docData.document_name}
        open={docOpen}
        onCancel={() => setDocOpen(false)}
        width={1200}
        footer={null}
      >
        <Otest docData={docData}/>
      </Modal>

      

    </div>
  )
}

export default Onlineservice









