import { Input, message, Modal, Select, Spin, Button, Row, Col, Table } from 'antd'
import React, { useEffect, useRef, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import context from '@/context/MainContext'
import dataApi from '@/api/dataApi'
import { ExclamationCircleFilled } from '@ant-design/icons';

const { confirm } = Modal

let indexObj: any = {}

let carid: string = ""
let vinData: any = [{vin: "", remark: ""}]
let activeIndex: any = -1

const Vinmodel: React.FC<any> = (props) => {
  const { store } = useContext(context)
  const { brandsList, brandData, writeAuth } = store
  const { pathname } = useLocation()

  const [keyword, setKeyword] = useState<string>("")
  const [brand, setBrand] = useState<string>(brandsList[0].value || '')
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [updateOpen, setUpdateOpen] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<any>({})

  const [operateLoading, setOperateLoading] = useState<boolean>(false)
  const [operateList, setOperateList] = useState<any>([])

  const [change, setChange] = useState<boolean>(false)

  let jexcelDivRef: any = useRef<HTMLDivElement>(null)
  let jexcelRef: any = useRef<any>({})

  let brandRef = useRef<any>(brandsList[0].value || '')


  useEffect(() => {
    initExcel()
  },[])

  useEffect(() => {
    brandRef.current = brand
    getList()
  },[brand])

  


  const initExcel = async () => {

    let excel: any = document.getElementById(pathname)
    let width = excel?.clientWidth
    let height = excel?.clientHeight
    let options: any = {
      showGrid: true,
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      mode: 'edit', //edit
      row: {
        len: 50000,
      },
      col: {
        len: 16,
        width: 130
      },
      view: {
        height: ()=>{return height},
        width: ()=>{return width},
      },
      
    }
    
    jexcelRef.current = new (window as any).x_spreadsheet(jexcelDivRef.current, options)
    jexcelRef.current.on("delete-row", (params: any) => {
      console.log(params)
    })

    jexcelRef.current.on('cell-selected', (e: any, ri: any, ci: any) => {
      if(ri) {
        console.log(e, ri, ci)
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        let _carid = rowData[indexObj['carid']] ? rowData[indexObj['carid']].text : ''
        carid = _carid
        if(ri && ci === indexObj['vin']) {
          setUpdateOpen(true)
          let params = {
            brand: brandRef.current,
            carsid: carid
          }
          activeIndex = ri
          
          dataApi.getModelVinDetail(params).then((res: any) => {
            if(res.code === 1) {
              if(res.data && res.data.length) {
                vinData = res.data
              }else {
                vinData = [{vin: "", remark: ""}]
              }
              setChange(change => !change)
            }
          })
        }else if(ri && ci === indexObj['operate']) {
          setOpen(true)
          setOperateLoading(true)
          dataApi.lookRecords({carsid: carid}).then((res: any) => {
            setOperateLoading(false)
            if(res.code === 1) {
              setOperateList(res.data)
            }
          })
        }
      }
      
    })
    
    console.log(jexcelRef.current)
    
    jexcelRef.current.validate()
    
  }
  const initData = async (list: any, headers: any) => {
    let _rows: any = { len: list.length + 1 }
    let cells: any = []
    headers.forEach((item: any) => {
      cells.push({
        text: item.label,
        editable: false,
        style: 0
      })
    })
    _rows[0] = {
      cells
    }
    let other: any = []
    list.forEach((items: any, j: number) => {
      let cells: any = []
      headers.forEach((item: any, index: number) => {
        indexObj[item.key] = index
        if(other.includes(item.key)) {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: false,
            style: 0
          })
        }else {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: true
          })
        }
        
      })
      _rows[j + 1] = {
        cells
      }
    })

    let excelDataObj = {
      name: '',
      merges: [],
      rows: _rows,
      autofilter: {
        filters: [],
        ref: 'A1:O1'
      },
      cols: {
        
      },
      freeze: "A2",
      styles: [
        {
          bgcolor: '#d8d8d8',
          font: {
            bold: true
          }
        },
        {
          color: '#ff0000'
        },
        {
          color: '#ec8d00'
        }
      ]
    }
    jexcelRef.current.loadData([excelDataObj])
  }
  const getList = () => {
    let params = {
      brand: brandRef.current,
      vin: keyword
    }
    setLoading(true)
    dataApi.getModelVinList(params).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        let column_name_dict: any = res.data.column_name_dict || {}
        let column_name_list: any = res.data.column_name_list || []
        let _header = column_name_list.map((name: string, index: number) => {
          let key = Object.keys(column_name_dict).filter((_key: any) => column_name_dict[_key] === name)[0] || ""
          return {
            label: name,
            key
          }
        })
        
        initData(res.data.row_list, [..._header])
      }
    })
  }


  const submit = () => {
    let _vin = vinData.map((it: any) => {
      if(it.vin || it.remark) {
        return [it.vin || "", it.remark || ""]
      }
    })
    let params = {
      brand: brandRef.current,
      vin: JSON.stringify(_vin),
      carsid: carid,
      operate: '操作'
    }
    setSubmitLoading(true)
    dataApi.updateModelVin(params).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        setUpdateOpen(false)
        let text = vinData.map((it: any) => it.vin).join(",")
        jexcelRef.current.datas[0].rows.setCell(activeIndex, indexObj['vin'], {
          text: text,
        }, 'all')
        jexcelRef.current.reRender()

        message.success("操作成功")
      }
    })
  }
  const setVinDataChange = (item: any, key: string, value: string) => {
    item[key] = value
    setChange(change => !change)
  }

  return (
    <div className='p10 bgc-fff' >
      <div className='flex-b-c'>
        <div className='flex-s-c'>
          <span>筛选：</span>
          <Select
            className="mr12"
            style={{ width: 170 }}
            placeholder="品牌"
            showSearch
            filterOption={(input: any, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={brandsList}
            value={brand || undefined}
            onChange={(value: any) => setBrand(value)}
          />
          <Input.Search onSearch={() => getList()} style={{ width: 220 }} value={keyword} onChange={(e: any) => { let _vin: string = e.target.value; setKeyword(_vin.toUpperCase())}} placeholder='请输入VIN'   />
        </div>
        {
          writeAuth[pathname] ? (
            <Button type="primary" ghost onClick={() => setOpen(true)} icon={<i className="iconfont icon-guanlian" />}>新增</Button>
          ): null
        }
      </div>
      
      <Spin spinning={loading}>
        <div className='mt10' style={{ height: 'calc(100vh - 110px)' }} ref={jexcelDivRef} id={pathname}></div>
      </Spin>
      <Modal
        title={
          <div className='flex-s-c'>车架号关联<i className='iconfont icon-tianjia p-color pointer ml5' onClick={() => {
            let _vinData = JSON.parse(JSON.stringify(vinData))
            _vinData.push({
              vin: '',
              remark: ''
            })
            vinData = _vinData
            setChange(change => !change)
          }} /></div>
        }
        open={updateOpen}
        onCancel={() => setUpdateOpen(false)}
        onOk={() => {
          submit()
        }}
        okButtonProps={{
          loading: submitLoading ? true : false
        }}
      >
        <div className='mtb20'>
          {
            vinData.map((item: any, index: number) => {
              return (
                <Row className='mt10' align="middle" key={index} gutter={10}>
                  <Col span={11}>
                    <Input placeholder='请输入VIN号' value={item.vin} onChange={(e: any) => setVinDataChange(item, 'vin', e.target.value)} />
                  </Col>
                  <Col span={11}>
                    <Input placeholder='备注' value={item.remark} onChange={(e: any) => setVinDataChange(item, 'remark', e.target.value)} />
                  </Col>
                  <Col span={2}>
                    <i className='iconfont icon-jianshao d-color pointer' onClick={() => {
                      let _vinData: any[] = JSON.parse(JSON.stringify(vinData))
                      _vinData.splice(index, 1)
                      vinData = _vinData
                      setChange(c => !c)
                    }} />
                  </Col>
                </Row>
              )
            })
          }
        </div>
      </Modal>

      <Modal
        title={`操作记录(${carid})`}
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        width={800}
      >
        <div className='mtb20'>
          <Table
            dataSource={operateList}
            columns={[
              {dataIndex: 'createtime', title: '时间'},
              {dataIndex: 'operate', title: '操作人'},
              {dataIndex: 'text', title: '修改内容',render: (text: any) => {
                return (
                  <div>
                    {
                      text.map((it: any, i: number) => {
                        return (
                          <div className='flex-b-c' key={i}>
                            <span className='mr10'>{it[0] || ''}</span>
                            <span>{it[1] || ''}</span>
                          </div>
                        )
                      })
                    }
                  </div>
                )
              }},
              {dataIndex: 'ystext', title: '修改前内容',render: (text: any) => {
                return (
                  <div>
                    {
                      text.map((it: any, i: number) => {
                        return (
                          <div className='flex-b-c' key={i}>
                            <span className='mr10'>{it[0] || ''}</span>
                            <span>{it[1] || ''}</span>
                          </div>
                        )
                      })
                    }
                  </div>
                )
              }},
            ] as any}
            loading={operateLoading}
            pagination={false}

          />
        </div>
      </Modal>
    </div>
  )
}

export default Vinmodel